import Vue from 'vue'
import Router from 'vue-router'

import routing from '@/app/config/routing'
import authService from '@/users/services/auth-service'


Vue.use(Router)

const router = new Router({
  routes: routing.routes,
})

// Handles redirect if the page requires authentication.
router.beforeEach((to, from, next) => {
  const recordsWithRoles = to.matched.filter((record) => record.meta.roles)
  if (recordsWithRoles.length > 0) {
    const record = recordsWithRoles[0]
    if (authService.isInAnyRole(record.meta.roles)) {
      next()
    } else {
      next(`/login/${to.name}`)
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authService.authenticated()) {
      next()
    } else {
      next(`/login/${to.name}`)
    }
  } else {
    next()
  }
})

export default router
