import Error from '@/app/views/error.vue'
import Home from '@/app/views/home.vue'
import Customers from '@/customers/views/customers.vue'
import Dashboard from '@/dashboard/views/dashboard.vue'
import InvoicePreview from '@/invoices/views/invoice-preview.vue'
import Invoices from '@/invoices/views/invoices.vue'
import Itineraries from '@/itineraries/views/itineraries.vue'
import Profile from '@/profile/views/profile.vue'
import Reports from '@/reports/views/reports.vue'
import Rides from '@/rides/views/rides.vue'
import Tenants from '@/tenants/views/tenants.vue'
import Login from '@/users/views/login.vue'
import Register from '@/users/views/register.vue'
import ResetPassword from '@/users/views/reset-password.vue'
import Settings from '@/users/views/settings.vue'
import Users from '@/users/views/users.vue'
import Categories from '@/customers/views/categories.vue'
import RideCategories from '@/rides/views/ride-categories.vue'
import CompanyUser from '@/users/views/company-user'

export default {

    // Default route the user will be redirected to if none is specified in the
    // request params.
    // loginDefaultRedirect: '/dashboard',
    loginDefaultRedirect: {
        ADMIN: '/tenants',
        TENANT: '/dashboard',
        DRIVER: '/dashboard',
        DEFAULT: '/dashboard',
    },
    // Route to redirect to after a successful logout.
    logoutRedirect: '/login',

    routes: [
        {
            path: '/', name: 'home', component: Login, meta: {
                showHeader: false,
                showSideMenu: false,
                fullscreen: true,
                title: 'Home',
            },
        },
        {
            path: '/register', name: 'register', component: Register, meta: {
                showHeader: false,
                showSideMenu: false,
                title: 'Registrieren',
            },
        },
        {
            path: '/login/:redirectUrl?', name: 'login', component: Login, meta: {
                showHeader: false,
                showSideMenu: false,
                fullscreen: true,
                title: 'Anmelden',
            },
        },
        {
            path: '/profile', name: 'profile', component: Profile, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                title: 'Profile',
            },
        },
        {
            path: '/reset-password/:token', name: 'reset-password', component: ResetPassword, props: true, meta: {
                showHeader: false,
                showSideMenu: false,
                fullscreen: true,
                title: 'Passwort zurücksetzen',
            },
        },

        {
            path: '/dashboard', name: 'dashboard', component: Dashboard, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                title: 'Dashboard',
            },
        },

        {
            path: '/customers', name: 'customers', component: Customers, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                // roles: ['TENANT'],
                title: 'Kunden',
            },
        },

        {
            path: '/invoices/:invoiceId?', name: 'invoices', component: Invoices, props: true, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                // roles: ['TENANT'],
                title: 'Rechnungen',
            },
        },

        {
            path: '/invoice/:invoiceId', name: 'invoice', component: InvoicePreview, props: true, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                // roles: ['TENANT']
                title: 'Rechnungsdetails',
            },
        },

        {
            path: '/itineraries', name: 'itineraries', component: Itineraries, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                // roles: ['TENANT']
                title: 'Steuersätze',
            },
        },

        // {
        //   path: '/recurringrides', name: 'recurringrides', component: RecurringRides, meta: {
        //     showSideMenu: true,
        //     requiresAuth: true,
        //     // roles: ['TENANT']
        //   },
        // },
        {
            path: '/rides', name: 'rides', component: Rides, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                // roles: ['TENANT'],
                title: 'Aufträge',
            },
        },
        {
            path: '/reports', name: 'reports', component: Reports, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                roles: ['TENANT'],
                title: 'Auswertungen',
            },
        },
        {
            path: '/settings', name: 'settings', component: Settings, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                roles: ['TENANT'],
                title: 'Firmendaten',
            },
        },
        {
            path: '/drivers', name: 'drivers', component: CompanyUser, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                roles: ['TENANT'],
                title: 'Benutzerverwaltung',
            },
        },
        {
            path: '/categories', name: 'categories', component: Categories, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                roles: ['TENANT'],
                title: 'Kundenkategorien',
            },
        },
        {
            path: '/ridecategories', name: 'ridecategories', component: RideCategories, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                //roles: ['TENANT'],
                title: 'Auftragskategorien',
            },
        },
        {
            path: '/tenants', name: 'tenants', component: Tenants, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                roles: ['ADMIN'],
                title: 'Mandanten',
            },
        },
        {
            path: '/users', name: 'users', component: Users, meta: {
                showHeader: true,
                showSideMenu: true,
                requiresAuth: true,
                roles: ['ADMIN'],
                title: 'Benutzerverwaltung',
            },
        },

        // Error page.
        {
            path: '/error/:status', name: 'error', component: Error, meta: {
                showSideMenu: false,
                fullscreen: true,
            },
        },

        // Fallback redirect.
        { path: '*', redirect: '/error/404' },
    ],
}
